<div
	data-testId="scar-progress-container"
	class="cp-scar-progress-container cp-center-content-column"
	[ngClass]="{ 'cp-scar-active': isActive === true,
	'cp-scar-complete': isComplete === true, 
	'cp-scar-inactive': isActive === false
	&& isComplete === false,
	'cp-icon-label-hidden' : shouldShowLabel === false}">
		<div class="cp-icon-container">
			<div [ngClass]="{'cp-active-selected' : isActive && scarSelected}">
				<a role="button" class="cp-scar-progress-bubble"
					[attr.data-id]="label + ' Icon Clicked'"
					[ngClass]="{'cp-selected' : scarSelected, 'cp-is-clickable' : isClickable}"
					(click)="scarIconSelected()"
					(keypress)="scarIconSelected()"
					tabindex="0"
					[attr.aria-label]="label"
					[attr.aria-current]="scarSelected ? 'true' : 'false'">
					<span *cpIfViewSize="['desktop', 'tablet']">
						<mat-icon *ngIf="!matIcon && svgIcon" [svgIcon]="svgIcon" class="cp-scar-svg"></mat-icon>
						<mat-icon *ngIf="matIcon" fontSet="material-symbols-outlined" class="cp-scar-icon">{{matIcon}}</mat-icon>
						<span class="cp-sr-only-aria">{{ isComplete ? 'Completed' : (isActive ? 'In Progress' : 'Not Started') }}</span>
					</span>
					<ng-container *cpIfViewSize="'mobile'">
						<mat-icon *ngIf="isComplete" fontSet="material-symbols-outlined">checkmark</mat-icon>
						<span *ngIf="!isComplete">{{step}}</span>
					</ng-container>
					<span class="cp-sr-only-aria">{{ isComplete ? 'Completed' : (isActive ? 'In Progress' : 'Not Started') }}</span>
				</a>
			</div>
		</div>

	<div *ngIf="shouldShowLabel" data-testId="scar-progress-label" class="cp-scar-progress-label" [ngClass]="{'cp-selected' : scarSelected === true}">
		<span>{{label}}</span>
	</div>
</div>